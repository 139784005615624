import React, { useEffect, useContext } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import { Form as BrowserForm , useParams, useLoaderData,  Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { TrackingContext } from 'ak-react-utils'

const ConfigEdit = () => {
  const data = useLoaderData()

  const { configId } = useParams()

  const {
    register,
    reset,
  } = useForm()

  // NOTE: set default form values this way until RFC is implemented
  // see: https://github.com/react-hook-form/react-hook-form/discussions/9046
  React.useEffect(() => {
    reset(data)
  }, [data, reset])

  const ctx = useContext(TrackingContext)

  useEffect(() => {
    ctx.tracker.trackPageView()
  }, [ctx.tracker])

  const {
    business_area
  } = data

  return (
    <>
      <h1 className='mb-3'>Einstellungen bearbeiten ({business_area})</h1>
      <BrowserForm method='patch' action={`/configs/${configId}`}
        className='vstack gap-3'
      >
        <Card text='dark'>
          <Card.Body className='vstack gap-3'>
            <Form.Check
              type='checkbox' id='reporting-live' label='Live Reports'
              {...register('reporting_live')}
            />
            <Form.Check
              type='checkbox' id='reporting-daily' label='Tägliche Reports'
              {...register('reporting_daily')}
            />
            <Form.Control
              type='email' id='reporting-email' placeholder="Enter destination Email"
              {...register('reporting_email')}
            />
          </Card.Body>
          <Card.Footer>
            <div className='hstack gap-2'>
              <Button type='submit' variant='outline-primary'>
                Save
              </Button>
              <Button as={Link} variant='outline-dark' to='/account'>
                Cancel
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </BrowserForm>
    </>
  )
}

export default ConfigEdit
