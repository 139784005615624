import React from 'react'

const dateFormatter = new Intl.DateTimeFormat('de-DE', {
  dateStyle: 'medium',
  timeStyle: 'long',
  timeZone: 'Europe/Berlin'
})

const FormattedDate = ({ value, fallbackValue }) => {
  if (value) {
    return dateFormatter.format(Date.parse(value))
  } else {
    return (<em>{ fallbackValue }</em>)
  }
}

export default FormattedDate
