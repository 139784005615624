import React, {useContext, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import AuthContext from '../AuthContext'

const AuthCallback = () => {
  const {completeLogin, hasPendingLogin} = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    const complete = async () => {
      let originalUrl = "/"

      if (!hasPendingLogin()) {
        return
      }

      try {
        originalUrl = await completeLogin()
      } catch (err) {
        console.error("Failed completing login", err)
      }

      navigate(originalUrl, { replace: true })
    }

    complete()
  }, [completeLogin, hasPendingLogin, navigate])

  return (<>Completing login...</>)
}

export default AuthCallback
