import React, { useEffect, useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { TrackingContext } from 'ak-react-utils'
import { useAuthContext } from '../AuthContext'

const Home = () => {

  const { isLoggedIn } = useAuthContext()
  const ctx = useContext(TrackingContext)

  useEffect(() => {
    console.log("About to track pageview - home")
    ctx.tracker.trackPageView()
  })

  if (isLoggedIn) {
    return (<Navigate to="/account" />)
  }

  return (
    <>
    <h1>Ihre Einstellungen für Services / Austauschkompass</h1>
    </>
  )
}

export default Home
