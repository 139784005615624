import React, {useContext} from 'react'
import AuthContext from '../AuthContext'
import { Navigate, useLocation } from 'react-router-dom'
import { createApiClient as createCrmApiClient } from '../CrmApiClient'
import { CrmApiContextProvider } from '../CrmApiContext'

const RequireCrmApi = ({ apiUrl, children }) => {
  const { isLoggedIn, accessToken } = useContext(AuthContext)
  const location = useLocation()

  if (isLoggedIn) {
    const client = createCrmApiClient({ apiUrl, apiToken: accessToken })
    return (
      <CrmApiContextProvider apiClient={client}>
        {children}
      </CrmApiContextProvider>
    )
  } else {
    return (<Navigate to="/login" state={{ from: location } }/>)
  }
}

export default RequireCrmApi
