import React, {useContext} from 'react'
import AuthContext from '../AuthContext'
import { Navigate, useLocation } from 'react-router-dom'
import { createApiClient as createOrderApiClient } from '../OrderApiClient'
import { OrderApiContextProvider } from '../OrderApiContext'

const RequireOrderApi = ({ apiUrl, children }) => {
  const { isLoggedIn, accessToken } = useContext(AuthContext)
  const location = useLocation()

  if (isLoggedIn) {
    const client = createOrderApiClient({ apiUrl, apiToken: accessToken })
    return (
      <OrderApiContextProvider apiClient={client}>
        {children}
      </OrderApiContextProvider>
    )
  } else {
    return (<Navigate to="/login" state={{ from: location } }/>)
  }
}

export default RequireOrderApi
