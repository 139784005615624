import { useContext, useEffect } from 'react'
import { AuthContext } from '../AuthContext'
import { TrackingContext } from 'ak-react-utils'

const LoginTracker = () => {

  const { isLoggedIn, decodedAccessToken: { user } } = useContext(AuthContext)
  const { tracker } = useContext(TrackingContext)

  useEffect(() => {
    if (isLoggedIn === true && tracker && user.email) {
      const email = user.email
      const organisation = user.company

      console.log("Tracking user login", user)

      tracker.trackDetailedEvent({
        category: "user",
        action: "login",
        name: organisation,
        value: email,
      })
    }
  }, [isLoggedIn, user.email, user.company, user, tracker])
}

export default LoginTracker
