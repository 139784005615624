import React from 'react'

import { Nav } from 'react-bootstrap'
import { Outlet, Link } from 'react-router-dom'

import MainNavigation from '../MainNavigation'

const GuestLayout = () => {
  return (
    <>
      <MainNavigation>
        <Nav className="ms-auto">
          <Nav.Link to='/login' as={Link}>Login</Nav.Link>
        </Nav>
      </MainNavigation>
      <main className="container">
        <Outlet/>
      </main>
    </>
  )
}

export default GuestLayout
