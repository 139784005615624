import React from 'react'

import { Link, useFetcher } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import FormattedDate from '../FormattedDate'

const ApiKeyTableRow = (apiKey) => {
  const fetcher = useFetcher()

  return (
    <tr key={apiKey.id}>
      <td>{apiKey.name}</td>
      <td>{apiKey.business_area}</td>
      <td>{apiKey.value}</td>
      <td>
        <FormattedDate fallbackValue='Unbenutzt' value={apiKey.last_used_at} />
      </td>
      <td className='hstack justify-content-end gap-2'>
        <Button as={Link} to={`/api_keys/${apiKey.id}`} size='sm' variant='outline-primary'>Anzeigen</Button>
        <fetcher.Form method="delete" action={`/api_keys/${apiKey.id}`}>
          <Button type='submit' size='sm' variant='outline-danger'>Löschen</Button>
        </fetcher.Form>
      </td>
    </tr>
  )
}

export default  ApiKeyTableRow
