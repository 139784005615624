import React from 'react'

import { Outlet } from 'react-router-dom'

import RequireOrderApi from '../RequireOrderApi'
import MainNavigation from '../MainNavigation'
import AuthenticatedNav from '../AuthenticatedNav'

const OrderLayout = ({ orderApiUrl }) => {
  return (
    <RequireOrderApi apiUrl={orderApiUrl}>
      <MainNavigation>
        <AuthenticatedNav />
      </MainNavigation>
      <main className='container'>
        <Outlet />
      </main>
    </RequireOrderApi>
  )
}

export default OrderLayout
