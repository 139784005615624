import React from 'react'
import { Alert } from 'react-bootstrap'
import { useRouteError } from 'react-router-dom'

const ErrorMessage = () => {
  const error = useRouteError()

  console.error(error)
  
  return (
    <Alert variant='danger'>
      <p className='mb-0'>
        {error.message}
      </p>
    </Alert>
  )
}

export default ErrorMessage
