import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient } from '@tanstack/react-query'
import { createBrowserRouter } from 'react-router-dom'
import AuthClient from './AuthClient'
import './index.css'
import AkCustomerApp from './AkCustomerApp'
import reportWebVitals from './reportWebVitals'

const rootEle = document.getElementById('ak-customer-app')
const oauthApplicationId = rootEle.dataset.oauthApplicationId
const oauthProviderUrl = rootEle.dataset.oauthProviderUrl
const crmApiUrl = rootEle.dataset.crmApiUrl
const orderApiUrl = rootEle.dataset.orderApiUrl

const authClient = new AuthClient({
  clientId: oauthApplicationId,
  providerUrl: oauthProviderUrl,
});

const queryClient = new QueryClient()

const doTrack = rootEle.dataset.matomoTracking === "true"
const matomoUrl = rootEle.dataset.matomoUrl
const matomoId = rootEle.dataset.matomoId

const root = ReactDOM.createRoot(rootEle)
root.render(
  <React.StrictMode>
    <AkCustomerApp
      authClient={authClient}
      queryClient={queryClient}
      crmApiUrl={crmApiUrl}
      orderApiUrl={orderApiUrl}
      createRouter={createBrowserRouter}
      matomoId={matomoId}
      matomoUrl={matomoUrl}
      matomoTracking={doTrack}
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
