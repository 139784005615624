import { createRoutesFromElements, Route, redirect } from 'react-router-dom'

import { createApiClient as createOrderApiClient } from './OrderApiClient'
import { createApiClient as createCrmApiClient } from './CrmApiClient'
import GuestLayout from './components/layouts/GuestLayout'
import CrmLayout from './components/layouts/CrmLayout'
import OrderLayout from './components/layouts/OrderLayout'
import AuthCallback from './components/AuthCallback'
import Home from './components/Home'
import Login from './components/Login'
import Account from './components/Account'
import ConfigEdit from './components/Config/Edit'
import AddressEdit from './components/Address/Edit'
import ApiKeyTable from './components/ApiKey/Table'
import ApiKeyShow from './components/ApiKey/Show'
import ApiKeyNew from './components/ApiKey/New'
import OrderTable from './components/Order/Table'
import OrderShow from './components/Order/Show'
import ErrorMessage from './components/ErrorMessage'
import NoAdminsHere from './components/NoAdminsHere'
import Docs from './components/Docs'

import jwt_decode from 'jwt-decode'

const createRoutes = ({ queryClient, authClient, crmApiUrl, orderApiUrl }) => {
  const requireAccessToken = async () => {
    if (!authClient.hasAccessToken()) {
      throw redirect("/login")
    }

    const accessToken = authClient.accessToken()
    const decodedToken = jwt_decode(accessToken)

    if (decodedToken.user.role !== 'company') {
      throw redirect("/no-admins-here")
    }

    return accessToken
  }

  const requireOrderApiAccess = async () => {
    const accessToken = await requireAccessToken()

    const client = createOrderApiClient({ apiUrl: orderApiUrl, apiToken: accessToken })

    return client
  }

  const requireCrmApiAccess = async () => {
    const accessToken = await requireAccessToken()

    const client = createCrmApiClient({ apiUrl: crmApiUrl, apiToken: accessToken })

    return client
  }

  const fetchParsedAccessToken = async () => {
    const accessToken = await requireAccessToken()

    return jwt_decode(accessToken)
  }

  const fetchApiKeys = async () => {
    const client = await requireOrderApiAccess()

    return queryClient.fetchQuery(['api_keys'], client.fetchApiKeys)
  }

  const fetchApiKey = async ({ params }) => {
    const client = await requireOrderApiAccess()

    return queryClient.fetchQuery(['api_keys', params.apiKeyId], () => client.fetchApiKey(params.apiKeyId))
  }

  const createApiKey = async ({ request }) => {
    const client = await requireOrderApiAccess()

    const formData = await request.formData()
    const values = Object.fromEntries(formData)
    const res = await client.createApiKey(values)
    await queryClient.invalidateQueries(['api_keys'])

    return redirect(`/api_keys/${res.id}`)
  }

  const handleApiKey = async ({ params, request }) => {
    const client = await requireOrderApiAccess()

    switch (request.method) {
      case 'PATCH':
        const formData = await request.formData()
        const values = Object.fromEntries(formData)
        const res = await client.updateApiKey(params.apiKeyId, values)
        await queryClient.invalidateQueries(['api_keys', params.apiKeyId])

        return redirect(`/api_keys/${res.id}`)
      case 'DELETE':
        if (!window.confirm(`Wird dieser Key gelöscht, funktionieren API-Verbindungen mit diesem Key nicht mehr! API Key ${params.apiKeyId} wirklich löschen?`)) {
          return
        }

        await client.destroyApiKey(params.apiKeyId)
        queryClient.invalidateQueries(['api_keys', params.apiKeyId])

        return redirect(`/api_keys`)
      default:
        throw new Error(`No method handler for ${request.method}`)
    }
  }

  const fetchOrders = async () => {
    const client = await requireOrderApiAccess()

    return queryClient.fetchQuery(['orders'], client.fetchOrders)
  }

  const fetchOrder = async ({ params }) => {
    const client = await requireOrderApiAccess()

    return queryClient.fetchQuery(['orders', params.orderId], () => client.fetchOrder(params.orderId))
  }

  const fetchFullAccount = async () => {
    const client = await requireCrmApiAccess()

    const account = await queryClient.fetchQuery(['account'], client.fetchAccount)
    const configs = await queryClient.fetchQuery(['configs'], client.fetchConfigs)
    const address = await queryClient.fetchQuery(['address'], client.fetchAddress)

    return {
      account,
      configs,
      address,
    }
  }

  const fetchAddress = async () => {
    const client = await requireCrmApiAccess()

    return queryClient.fetchQuery(['address'], client.fetchAddress)
  }

  const handleAddress = async ({ request }) => {
    const client = await requireCrmApiAccess()

    switch(request.method) {
      case 'PATCH':
        const formData = await request.formData()
        const values = Object.fromEntries(formData)

        await client.updateAddress(values)
        queryClient.invalidateQueries(['address'])

        return redirect(`/account`)
      default:
        throw new Error(`No method handler for '${request.method}' address`)
    }
  }

  const fetchConfig = async ({params}) => {
    const client = await requireCrmApiAccess()

    return queryClient.fetchQuery(['configs', params.configId], client.fetchConfig({id: params.configId}))
  }

  const handleChangeConfig = async ({ params, request }) => {
    const client = await requireCrmApiAccess()

    switch(request.method) {
      case 'PATCH':
        const formData = await request.formData()
        const values = Object.fromEntries(formData)

        values.reporting_daily = !!values.reporting_daily
        values.reporting_live = !!values.reporting_live

        await client.updateConfig(params.configId, values)
        queryClient.invalidateQueries(['configs', params.configId])

        return redirect(`/account`)
      default:
        throw new Error(`No method handler for '${request.method}' config`)
    }
  }

  const routes = createRoutesFromElements(
    <Route>
      <Route element={<GuestLayout />}>
        <Route exact path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="oauth/callback" element={<AuthCallback />} />
        <Route path="no-admins-here" element={<NoAdminsHere />} />
      </Route>

      <Route element={<CrmLayout crmApiUrl={crmApiUrl} />}>

        <Route
          exact
          path="/account"
          element={<Account />}
          loader={fetchFullAccount}
        />
        <Route exact path="/configs/:configId"
          errorElement={<ErrorMessage />}
          element={<ConfigEdit />}
          loader={fetchConfig}
          action={handleChangeConfig}
        />
        <Route path="/address" action={handleAddress} errorElement={<ErrorMessage />}>
          <Route index />
          <Route path="edit" element={<AddressEdit />} loader={fetchAddress}/>
        </Route>
      </Route>

      <Route element={<OrderLayout />} >
        <Route path="/docs" element={<Docs />} />
        <Route exact path="/orders"
          element={<OrderTable />}
          loader={fetchOrders}
          errorElement={<ErrorMessage />}
        />
        <Route exact
          path="/orders/:orderId"
          element={<OrderShow />}
          loader={fetchOrder}
          errorElement={<ErrorMessage />}
        />

        <Route path="/api_keys" action={createApiKey} errorElement={<ErrorMessage />}>
          <Route index element={<ApiKeyTable />} loader={fetchApiKeys} />
          <Route path="new" element={<ApiKeyNew />} loader={fetchParsedAccessToken}/>
          <Route path=":apiKeyId" action={handleApiKey}>
            <Route index element={<ApiKeyShow />} loader={fetchApiKey} />
          </Route>
        </Route>
      </Route>
    </Route>
  )

  return routes
}

export default createRoutes
