/*
   This module most likely will be extracted into ak-react-utils
   as soon as other consumers need it...
 */
import React, { createContext, useState, useContext } from 'react'
import jwt_decode from 'jwt-decode'

const AuthContext = createContext({})

const AuthContextProvider = ({authClient, children}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(authClient.hasAccessToken())
  // accessToken may initially be nil
  const [accessToken, setAccessToken] = useState(authClient.accessToken())
  const [decodedAccessToken, setDecodedAccessToken] = useState({ user: {} })

  const beginLogin = ({ originalUrl }) => {
    authClient.authorize({ originalUrl })
  }

  const completeLogin = async () => {
    const originalUrl = await authClient.exchangeAuthCodeForAccessToken()

    const token = authClient.accessToken()
    setAccessToken(token)
    setDecodedAccessToken(jwt_decode(token))
    setIsLoggedIn(true)

    return originalUrl
  }

  const hasPendingLogin = () => {
    return authClient.hasPendingAccessToken()
  }

  return (
    <AuthContext.Provider
      value={{
        beginLogin,
        completeLogin,
        hasPendingLogin,
        isLoggedIn,
        accessToken,
        decodedAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuthContext = () => {
  const authContext = useContext(AuthContext)

  return authContext
}

export {
  AuthContext,
  AuthContextProvider,
  useAuthContext,
}

export default AuthContext
