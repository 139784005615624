import React from 'react'

import { Table, Card } from 'react-bootstrap'
import { useLoaderData, Link } from 'react-router-dom'

import ApiKeyTableRow from './TableRow'

const ApiKeyTable = () => {
  const apiKeys = useLoaderData()

  return (
    <>
      <h1 className='text-white'>
        API Keys
      </h1>
      <p>Mit diesen können Bestellungen via Bestell-Api abgerufen werden.</p>
      <Card>
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Geschäftsbereich</th>
                <th>Value</th>
                <th>Zuletzt verwendet</th>
                <th></th>
              </tr>
            </thead>
            <tbody className='table-group-divider'>
              {apiKeys.map((apiKey) => (
                <ApiKeyTableRow key={apiKey.id} {...apiKey} />
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          <Link to='/api_keys/new' className='btn btn-outline-primary'>Neuer API Key</Link>
        </Card.Footer>
      </Card>
    </>
  )
}

export default ApiKeyTable
