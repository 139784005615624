import React, { useContext, useEffect } from 'react'

import { Card, Button } from 'react-bootstrap'
import { useLoaderData, Link } from 'react-router-dom'
import { TrackingContext } from 'ak-react-utils'
import FormattedDate from '../FormattedDate'

const ApiKeyShow = () => {
  const apiKey = useLoaderData()

  const ctx = useContext(TrackingContext)

  useEffect(() => {
    console.log("About to track pageview - api-keys")
    ctx.tracker.trackPageView()
  }, [ctx.tracker])

  return (
    <>
      <h1>API Key <em>{apiKey.name}</em></h1>
      <Card text='dark'>
        <Card.Body>
          <dl>
            <dt>Name</dt>
            <dd>{apiKey.name}</dd>
            <dt>Geschäftsbereich</dt>
            <dd>{apiKey.business_area}</dd>
            <dt>Value</dt>
            <dd>{apiKey.value}</dd>
            <dt>Zuletzt verwendet</dt>
            <dd>
              <FormattedDate fallbackValue='Unbenutzt' value={apiKey.last_used_at} />
            </dd>
          </dl>
        </Card.Body>
        <Card.Footer>
          <Button as={Link} to='/api_keys' variant='outline-primary' >Back</Button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default ApiKeyShow
