import React, { useContext, useEffect } from 'react'
import { Card, Button, Row, Col } from 'react-bootstrap'
import { useLoaderData, Link } from 'react-router-dom'
import { TrackingContext } from 'ak-react-utils'

const OrderShow = () => {

  const order = useLoaderData()

  const ctx = useContext(TrackingContext)

  useEffect(() => {
    console.log("About to track pageview - orders#show")
    ctx.tracker.trackPageView()
  }, [ctx.tracker])

  return (
    <>
      <h1>Bestellung vom {order.created_at}</h1>
      <Card text='dark'>
        <Card.Body>
          <Row>
          <Col>
            <h3>Lead</h3>
            <p>
              {order.first_name} {order.last_name}<br />
              {order.email}<br />
              {order.phone}<br />
              {order.street} {order.street_number}<br />
              {order.zip_code} {order.city}<br />
              {order.country_code}
            </p>
          </Col>
          <Col>
          <dl>
            <dt>Typ</dt>
            <dd>{order.type}</dd>
            <dt>Geschäftsbereich</dt>
            <dd>{order.business_area}</dd>
            <dt>Interesse an Produktwerbung</dt>
            <dd>{order.terms_and_conditions ? 'Ja' : 'Nein'}</dd>
            <dt>Content</dt>
            <dd>{JSON.stringify(order.content)}</dd>
          </dl>
          </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Button as={Link} to='/orders' variant='outline-primary' >Back</Button>
        </Card.Footer>
      </Card>
    </>
  )
}

export default OrderShow
