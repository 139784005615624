import React from 'react'

import { Button, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { findCountryName } from '../../utils'

const AddressCard = (address) => {
  return (
    <Card text='dark' className='h-100'>
      <Card.Body>
        <Card.Title>Adresse</Card.Title>
        <dl>
          <dt>Straße</dt>
          <dd>{address.street}</dd>
          <dt>Hausnummer</dt>
          <dd>{address.street_number}</dd>
          <dt>Stadt</dt>
          <dd>{address.city}</dd>
          <dt>Land</dt>
          <dd>{findCountryName(address.country_code)}</dd>
        </dl>
      </Card.Body>
      <Card.Footer>
        <Button as={Link} to='/address/edit' variant='outline-primary'>Bearbeiten</Button>
      </Card.Footer>
    </Card>
  )

}

export default AddressCard
