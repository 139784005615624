const prepareApiRequest = ({ apiUrl, apiToken, endpoint }) => {
  return {
    url: `${apiUrl}${endpoint}`,
    headers: {
      'Authorization': `Bearer ${apiToken}`
    },
    mode: 'cors'
  }
}

const withResponseHandling = async (res) => {
  let json
  try {
    json = await res.json()
  } catch(e) {
    throw new Error(`Content is kein JSON: ${e.message}`)
  }

  if (res.ok) {
    return json
  } else {
    throw new Error(`Api Error: ${JSON.stringify(json)}`)
  }
}

const withStatusHandling = async (res) => {
  if (res.ok) {
    return res
  } else {
    throw new Error(`Api Error: ${await res.text()}`)
  }
}

const createApiClient = ({apiUrl, apiToken}) => {
  return {
    fetchApiKeys: async () => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/customer/api/v1/api_keys`
      })

      const res = await fetch(url, {
        headers: headers
      })
      return withResponseHandling(res)
    },
    fetchApiKey: async (apiKeyId) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/customer/api/v1/api_keys/${apiKeyId}`
      })

      const res = await fetch(url, {
        headers: headers
      })
      return withResponseHandling(res)
    },
    createApiKey: async (apiKey) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/customer/api/v1/api_keys`
      })
      const res = await fetch(url, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify(apiKey)

      })
      return withResponseHandling(res)
    },
    updateApiKey: async (id, apiKey) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/customer/api/v1/api_keys/${id}`
      })
      const res = await fetch(url, {
        headers: headers,
        method: 'PATCH',
        body: JSON.stringify(apiKey)

      })
      return withResponseHandling(res)
    },
    destroyApiKey: async (id) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/customer/api/v1/api_keys/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'DELETE'
      })
      return withStatusHandling(res)
    },
    fetchOrders: async () => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/customer/api/v1/orders`
      })

      const res = await fetch(url, {
        headers: headers
      })
      return withResponseHandling(res)
    },
    fetchOrder: async (orderId) => {
      const {url, headers} = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/customer/api/v1/orders/${orderId}`
      })

      const res = await fetch(url, {
        headers: headers
      })
      return withResponseHandling(res)
    },
  }
}

export {
  createApiClient
}
