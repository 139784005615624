import React, { useContext, useEffect } from 'react'
import { Table, Card, Button } from 'react-bootstrap'
import { useLoaderData, Link } from 'react-router-dom'
import { TrackingContext } from 'ak-react-utils'


const OrderTable = () => {
  const orders = useLoaderData()

  const ctx = useContext(TrackingContext)

  useEffect(() => {
    console.log("About to track pageview - orders")
    ctx.tracker.trackPageView()
  })

  // in place sorting
  orders.sort((a, b) => {
    return a.created_at.localeCompare(b.created_at)
  })



  orders.reverse()

  return (
    <>
      <h1>{orders.length} Bestellung/en </h1>
      <p>für vergangene 2 Monate</p>
      <Card>
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>Vom</th>
                <th>Type</th>
                <th>Geschäftsbereich</th>
                <th>Lead-Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody className='table-group-divider'>
              {orders.map((o) => (
                <tr key={o.id}>
                  <td>{o.created_at}</td>
                  <td>{o.type}</td>
                  <td>{o.business_area}</td>
                  <td>{o.email}</td>
                  <td className='text-end'>
                    <Button as={Link} to={`/orders/${o.id}`} size='sm' variant='outline-primary'>Anzeigen</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  )
}

export default OrderTable
