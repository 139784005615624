import React, { useContext, useEffect } from 'react'

import { akCountriesWithSubdivisions } from 'ak_countries'
import { Form, Card, Button } from 'react-bootstrap'
import { Form as BrowserForm, Link, useLoaderData } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { TrackingContext } from 'ak-react-utils'

const AddressEdit = () => {
  const data = useLoaderData()

  const ctx = useContext(TrackingContext)

  const {
    register,
    reset,
  } = useForm()

  useEffect(() => {
    ctx.tracker.trackPageView()
  }, [ctx.tracker])

  // NOTE: set default form values this way until RFC is implemented
  // see: https://github.com/react-hook-form/react-hook-form/discussions/9046
  React.useEffect(() => {
    reset(data)
  }, [data, reset])

  return (
    <>
      <h1>
        Adresse bearbeiten
      </h1>

      <BrowserForm method='patch' action={`/address`} className='vstack gap-3'>
        <Card text='dark'>
          <Card.Body className='vstack gap-3'>
            <Form.Group>
              <Form.Label>
                Straße
              </Form.Label>
              <Form.Control {...register('street')} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Hausnummer
              </Form.Label>
              <Form.Control {...register('street_number')} />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Stadt
              </Form.Label>
              <Form.Control {...register('city')} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Land
              </Form.Label>
              <Form.Select {...register('country_code')} required>
                <option value=''>— Land auswählen —</option>
                {akCountriesWithSubdivisions.map((country) => (
                  <option key={country.code} value={country.code}>{country.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Card.Body>
          <Card.Footer>
            <div className='hstack gap-3'>
              <Button type='submit' variant='outline-primary'>
                Speichern
              </Button>
              <Button as={Link} to='/api_keys' variant='outline-dark' >Back</Button>
            </div>
          </Card.Footer>
        </Card>
      </BrowserForm>
    </>
  )
}

export default AddressEdit
