import React from 'react'
import { Alert } from 'react-bootstrap'

const NoAdminsHere = () => (
  <>
    <Alert>
      <h1>Oops</h1>
      <p>
        Du bist Admin, diese App richtet sich an Organisationen.
      </p>
    <p className='mb-0'>
      Wahrscheinlich wolltest du ins <a href='https://admin.austauschkompass.de'>
        AdminDashboard AustauschKompass
      </a>.
    </p>
    </Alert>
  </>
)

export default NoAdminsHere
