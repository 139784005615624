import React from 'react'

import { NavLink } from 'react-router-dom'
import { Nav } from 'react-bootstrap'

const AuthenticatedNav = () => (
  <Nav>
    <Nav.Link to="/account" as={NavLink}>Account</Nav.Link>
    <Nav.Link to="/orders" as={NavLink}>Bestellungen</Nav.Link>
    <Nav.Link to="/api_keys" as={NavLink}>API Keys</Nav.Link>
    <Nav.Link to="/docs" as={NavLink}>API Documentation</Nav.Link>
  </Nav>
)

export default AuthenticatedNav
