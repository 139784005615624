import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { AuthContextProvider } from './AuthContext'
import { RouterProvider } from 'react-router-dom'
import { Tracking, TrackingProvider } from 'ak-react-utils'
import LoginTracker from './components/LoginTracker'
import createRoutes from './createRoutes'
import './styles/index.scss';

const AkCustomerApp = ({
  authClient,
  queryClientOptions,
  crmApiUrl,
  orderApiUrl,
  createRouter,
  matomoId,
  matomoTracking,
  matomoUrl
}) => {
  const queryClient = new QueryClient(queryClientOptions)
  const routes = createRoutes({ queryClient, authClient, crmApiUrl, orderApiUrl })
  const router = createRouter(routes)
  const tracker = new Tracking({
    enabled: matomoTracking,
    gaId: "0",
    mtId: matomoId,
    mtUrl: matomoUrl,
    debug: true
  })


  return (
    <>
      <TrackingProvider tracker={tracker} >
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider authClient={authClient}>
            <RouterProvider router={router} />
            <LoginTracker />
          </AuthContextProvider>
        </QueryClientProvider>
      </TrackingProvider>
    </>
  )
}

export default AkCustomerApp
