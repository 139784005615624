import React, { createContext, useState, useContext } from 'react'

const CrmApiContext = createContext({})

const CrmApiContextProvider = ({ apiClient, children }) => {
  const [client, setClient] = useState(apiClient)
  return (
    <CrmApiContext.Provider value={{ client, setClient }}>
      {children}
    </CrmApiContext.Provider>
  )
}

const useCrmApiClient = () => {
  const { client } = useContext(CrmApiContext)

  return client
}

export {
  CrmApiContext,
  CrmApiContextProvider,
  useCrmApiClient,
}

export default CrmApiContext
