import React from 'react'

import { Outlet } from 'react-router-dom'

import RequireCrmApi from '../RequireCrmApi'
import MainNavigation from '../MainNavigation'
import AuthenticatedNav from '../AuthenticatedNav'

const CrmLayout = ({ crmApiUrl }) => {
  return (
    <RequireCrmApi apiUrl={crmApiUrl}>
      <MainNavigation>
        <AuthenticatedNav />
      </MainNavigation>
      <main className='container'>
        <Outlet />
      </main>
    </RequireCrmApi>
  )
}

export default CrmLayout
