import { Navbar, Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const MainNavigation = ({ children }) => {

  return (
    <Navbar bg="dark" variant="dark" expand="md">
      <Container>
        <NavLink className='navbar-brand' to="/">
          Customer.austauschkompass.de
        </NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="text-end">
          {children}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )

}

export default MainNavigation
