import React, { useContext, useEffect } from 'react'
import { Form, Card, Button } from 'react-bootstrap'
import { Form as BrowserForm, Link, useLoaderData } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { akBusinessAreas } from 'ak_business_constants'
import { TrackingContext } from 'ak-react-utils'

const ApiKeyNew = () => {
  const parsedAccessToken = useLoaderData()
  const {
    user: {
      business_areas
    }
  } = parsedAccessToken

  const ctx = useContext(TrackingContext)

  useEffect(() => {
    ctx.tracker.trackPageView()
  }, [ctx.tracker] )

  const availableBusinessAreas = akBusinessAreas.filter((area) => business_areas.includes(area))

  let defaultBusinessArea

  if (availableBusinessAreas.length === 1) {
    defaultBusinessArea = availableBusinessAreas[0]
  }

  const {
    register,
  } = useForm({
    defaultValues: {
      business_area: defaultBusinessArea
    }
  })

  return (
    <>
      <h1>
        Neuer API Key
      </h1>

      <BrowserForm method='post' action={`/api_keys`} className='vstack gap-3'>
        <Card text='dark'>
          <Card.Body className='vstack gap-3'>
            <Form.Group>
              <Form.Label>
                Name
              </Form.Label>
              <Form.Control {...register('name')} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Geschäftsbereich
              </Form.Label>
              <Form.Select {...register('business_area')} required>
                <option value=''>— Geschäftsbereich auswählen —</option>
                {availableBusinessAreas.map((area) => (
                  <option key={area} value={area}>{area}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Card.Body>
          <Card.Footer>

            <div className='hstack gap-3'>
              <Button type='submit' variant='outline-primary'>
                Speichern
              </Button>
              <Button as={Link} to='/api_keys' variant='outline-dark' >Back</Button>
            </div>
          </Card.Footer>
        </Card>
      </BrowserForm>
    </>
  )
}

export default ApiKeyNew
