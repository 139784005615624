const prepareApiRequest = ({ apiUrl, apiToken, endpoint }) => {
  return {
    url: `${apiUrl}${endpoint}`,
    headers: {
      'Authorization': `Bearer ${apiToken}`
    },
    mode: 'cors'
  }
}

const withResponseHandling = async (res) => {
  let json
  try {
    json = await res.json()
  } catch(e) {
    throw new Error(`Content is kein JSON: ${e.message}`)
  }

  if (res.ok) {
    return json
  } else {
    throw new Error(`Api Error: ${JSON.stringify(json)}`)
  }
}

const createApiClient = ({ apiUrl, apiToken }) => {
  return {
    fetchAccount: async () => {
      const { url, headers } = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/api/customer/account`
      })

      const res = await fetch(url, {
        headers: headers
      })
      return withResponseHandling(res)
    },
    fetchConfigs: async () => {
      const { url, headers } = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/api/customer/configs`
      })

      const res = await fetch(url, {
        headers: headers
      })
      return withResponseHandling(res)
    },
    fetchConfig: ({ id }) => {
      return async () => {
        const { url, headers } = prepareApiRequest({
          apiUrl: apiUrl,
          apiToken: apiToken,
          endpoint: `/api/customer/configs/${id}`
        })

        const res = await fetch(url, {
          headers: headers
        })
        return withResponseHandling(res)
      }
    },
    updateConfig: async (id, config) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/api/customer/configs/${id}`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'PATCH',
        body: JSON.stringify(config)
      })

      return withResponseHandling(res)
    },
    fetchAddress: async () => {
      const { url, headers } = prepareApiRequest({
        apiUrl: apiUrl,
        apiToken: apiToken,
        endpoint: `/api/customer/address`
      })

      const res = await fetch(url, {
        headers: headers
      })
      return withResponseHandling(res)
    },
    updateAddress: async (address) => {
      const { url, headers } = prepareApiRequest({
        apiUrl,
        apiToken,
        endpoint: `/api/customer/address`
      })

      const res = await fetch(url, {
        headers: headers,
        method: 'PATCH',
        body: JSON.stringify(address)
      })

      return withResponseHandling(res)
    },
  }
}

export {
  createApiClient
}
