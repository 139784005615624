import React, { useContext, useEffect } from 'react'
import { Card, Col } from 'react-bootstrap'
import { useLoaderData } from 'react-router-dom'
import ConfigCard from './Config/Card'
import AddressCard from './Address/Card'
import { TrackingContext } from 'ak-react-utils'

const Account = () => {

  const ctx = useContext(TrackingContext)

  const {
    account: {
      name,
      shortname,
      slug,
      logo_url,
      business_form,
    },
    address,
    configs,
  } = useLoaderData()

  useEffect(() => {
    ctx.tracker.trackPageView()
  }, [ctx.tracker])

  return (
    <>
      <h1 className='mb-3'>Account: {name}</h1>
      <div className='row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4 mb-5'>
        <div className='col'>
          <Card text='dark' className='h-100'>
            <Card.Img variant='top' alt={`${name} Logo`} src={logo_url} />
            <Card.Footer>
              <dl className='mb-0'>
                <dt>Name</dt>
                <dd>{name}</dd>
                <dt>Kurzname</dt>
                <dd>{shortname}</dd>
                <dt>Maschinenlesbarer Name</dt>
                <dd>{slug}</dd>
                <dt>Geschäftsform</dt>
                <dd>{business_form}</dd>
              </dl>
            </Card.Footer>
          </Card>
        </div>
        <Col>
          <AddressCard {...address} />
        </Col>
        {configs.map((cfg) => (
          <div key={cfg.id} className='col'>
            <ConfigCard {...cfg}/>
          </div>
        ))}
      </div>
    </>
  )
}

export default Account
