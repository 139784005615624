import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { TrackingContext } from 'ak-react-utils'
import AuthContext from '../AuthContext'

const Login = () => {
  const { beginLogin, isLoggedIn } = useContext(AuthContext)
  const location = useLocation()
  const originalUrl = location.state?.from?.pathname || "/"

  useEffect(() => {
    if (!isLoggedIn) {
      beginLogin({ originalUrl })
    }
  }, [isLoggedIn, beginLogin, originalUrl])

  const ctx = useContext(TrackingContext)

  useEffect(() => {
    ctx.tracker.trackPageView()
  }, [ctx.tracker])

  if (isLoggedIn) {
    return (<>You are already logged in!</>)
  } else {
    return (<>Logging you in...</>)
  }
}

export default Login
