import React, { useEffect, useContext } from 'react'
import { Card, Button } from 'react-bootstrap'
import { akTargetGroups } from 'ak_business_constants'
import { Link } from 'react-router-dom'
import { findCountryName } from '../../utils'
import { TrackingContext } from 'ak-react-utils'

const YesOrNo = ({ on }) => {
  return on ? "Ja" : "Nein"
}

const findTargetGroup = (id) => {
  return akTargetGroups.find((group) => id === group.id).name
}

const ConfigCard = (cfg) => {

  const ctx = useContext(TrackingContext)

  useEffect(() => {
    ctx.tracker.trackPageView()
  }, [ctx.tracker])

  return (
    <Card className='text-dark h-100'>
      <Card.Body>
        <Card.Title className='mb-3'>Einstellungen ({ cfg.business_area })</Card.Title>
        <Card.Text as='div'>
          <dl>
            <dt>Reporting Email</dt>
            <dd>{cfg.reporting_email}</dd>
            <dt>Reporting live</dt>
            <dd><YesOrNo on={cfg.reporting_live} /></dd>
            <dt>Reporting täglich</dt>
            <dd><YesOrNo on={cfg.reporting_daily} /></dd>
            <dt>Zielgruppen</dt>
            <dd>{cfg.target_groups.map(findTargetGroup).join(", ")}</dd>
            <dt>Zielländer</dt>
            <dd>{cfg.target_countries.map(findCountryName).join(", ")}</dd>
          </dl>
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <Button as={Link} to={`/configs/${cfg.id}`} variant='outline-primary'>Bearbeiten</Button>
      </Card.Footer>
    </Card>
  )
}

export default ConfigCard
