import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { TrackingContext } from 'ak-react-utils'

const Docs = () => {

  const ctx = useContext(TrackingContext)

  useEffect(() => {
    ctx.tracker.trackPageView()
  }, [ctx.tracker] )

  const example =  (
    <code>
    <pre>`{`
        [
            {
              "business_area" : "sprachreisen",
              "city" : "Nürnberg",
              "content" : {
                "birth_year" : 2007,
                "media_preference" : "print_and_pdf",
                "target_group" : "teenagers"
              },
              "country_code" : "DE",
              "created_at" : "2023-04-19T19:26:40.627715+02:00",
              "email" : "Susi.herweg@web.de",
              "first_name" : "Susanne",
              "id" : "dd69b6d2-c819-4132-b463-1234521f574",
              "last_name" : "Oste",
              "phone" : "0177512346",
              "street" : "Weg",
              "street_number" : "1",
              "terms_and_conditions" : true,
              "type" : "catalog_order",
              "zip_code" : "12300"
            },
            {
              ...
            },
         ]

    `}`</pre>
    </code>
  )

  const applicationOrderExample = (
    <code>
      <pre>
        {`{
  "id" : "dd69b6d2-c819-4132-b463-1234521f573",
  "country_code" : "DE",
  "created_at" : "2023-04-19T19:26:40.627715+02:00",
  "email" : "test@example.com",
  "first_name" : "Mirko",
  "last_name" : "Mustermann",
  "phone" : "0177512346",
  "street" : "Beispielstraße",
  "street_number" : "1",
  "terms_and_conditions" : true,
  "type" : "application_order",
  "zip_code" : "12300"
  "business_area" : "schueleraustausch",
  "city" : "Nürnberg",
  "content" : {
    "program_name":"1 Schuljahr USA August 2023",
    "program_id":"42",
    "date_of_birth":"2018-01-01T22:00:00.000Z",
    "gender":"female",
    "nationality":"deutsch",
    "guardian_names":[
      "Max Mustermann",
      "Martha Mustermann"
    ],
    "school_type":"Gymnasium",
    "school_name":"Beispielschule",
    "school_city":"Hamburg",
    "school_zip_code":"20099",
    "school_country_code":"DE",
    "school_street":"Schulterblatt",
    "average_grade_english":"2",
    "average_grade":"2"
  }
}`}
      </pre>
    </code>
  )

  return(
    <>
    <h1>Docs</h1>
    <div className='card bg-light'>
      <div className='card-body text-dark'>
        <h2>API Zugriff</h2>
        <p className='lead'>
          Mit ihrem <Link to='/api_keys'>ApiKey</Link> können Sie ihre Bestellungen der letzten 2 Monate im entsprechenden
          Geschäftsbereich abrufen.
        </p>
        <p>
          Ihr <Link to='/api_keys'>ApiKey</Link> ist einem Geschäftsbereich zugeordnet:
          <code> schueleraustausch</code> oder
          <code> sprachreisen</code>.<br />
          Für den Fall, dass Ihr <Link to='/api_keys'>ApiKey</Link>  kompromittiert wurde, löschen Sie diesen einfach mit
          dem entsprechenden Button.<br />
          Zur Verwendung unserer API lesen Sie die abschließende Dokumentation der API-Endpunkte.
        </p>
        <h2>API-Endpunkte</h2>
        <p>
          JSON-API zum Abruf von Katalogbestellungen (künftig über diesen Api Endpunkt auch Programmanfragen und Terminanmeldungen)
        </p>
        <p>
          <strong>Endunkte</strong><br />
          Die API ist auf <code>api.orders.austauschkompass.de</code> unter dem Namensraum
          <code>/organisation/api/v1/</code> zu erreichen. <br />
        </p>
        <p>
          Endpunkt für den Abruf von Bestellungen ist <code>orders</code>.<br />
          Unter:
          <code> https://api.orders.austauschkompass.de/organisation/api/v1/orders</code><br />
          Können die Daten abgefragt werden. Alle Anfragen werden grundsätzlich SSL verschlüsselt!
        </p>
        <h2>Authentifizierung</h2>
        <p>
          Der Abruf setzt einen Authorization Header in der Anfrage voraus.
          Dieser muss einen der erstellten API-Tokens enthalten.<br />
          Mit dem Kommandozeilenprogramm curl kann dies testweise so:
        </p>
        <p>
          <code>$: curl -H "Authorization: Bearer your-token-comes-here" -H "Accept: application/json"
          https://api.orders.austauschkompass.de/organisation/api/v1/orders</code>
        </p>
        <p>
          abgerufen werden.
        </p>
        <p>
          Wir empfehlen für die Integration den Einsatz eines REST-Clients in der
          jeweils eingesetzten Programmiersprache.
        </p>
        <h2>Datenschutz</h2>
        <p>
          Bitte beachten Sie, dass die in den API-Antworten enthaltene E-Mail und Telefonnummer ausschließlich
          für organisatorische Zwecke, gemäß der Kooperationsvereinbarung verwendet werden dürfen. Bei Hinweis,
          dass Interesse an Produktwerbung signalisiert wurde (<code>"terms_and_conditions": true</code>),
          dürfen E-Mail, bzw. Telefonnummer gemäß der Kooperationsvereinbarung für Werbezwecke genutzt werden.
        </p>
        <h2>Beispiele</h2>
        <p>
          Im Folgenden sind exemplarisch einige Abrufvarianten der API gelistet. Die verwendeten Parameter sind
          nochmals separat gelistet und können teilweise kombiniert eingesetzt werden.
        </p>
        <p>
          Der Abruf der Daten über unsere JSON-Api setzt einen gesetzten Content-Type Header voraus: <br />
          <code> Accept: application/json</code>
        </p>
        <h3>Abrufe</h3>
        <p>
          <strong>Achtung:</strong> Ohne weitere Parameter wird als Abfragezeitraum
          der gestrige Tag verwendet. <br />
          Bei Verwendung der Datums oder Zeiteinschränkenden Parameter werden Grundsätzlich bis maximal 2 Monate
          zurückliegende Bestellungen gelistet
        </p>
        <p>
          Abgerufener Pfad <code>/organisation/api/v1/orders</code>
        </p>
        <p>
          <code>
          curl -H "Authorization: Bearer your-token-here" \ <br />
          -H "Accept: application/json" \ <br />
          "https://api.orders.austauschkompass.de/organisation/api/v1/orders?start_time=2023-04-19T09%3A00%3A00%2B02%3A00&end_time=2023-04-19T09%3A00%3A00%2B02%3A00" \ <br />
          | json_pp
          </code>
        </p>
        <h3>Antwortbeispiel</h3>
        <p>Json-Object Array</p>
        <p>
          {example}
        </p>
        <h4>Beispiel: Kurzbewerbung (<code>application_order</code>)</h4>
        <p>
          Bitte beachten Sie, dass dies nur ein einzelnes JSON-Object zeigt. Der oben gezeigte JSON-Array enthält die verschiedenen Bestelltypen.
        </p>
        <p>
          {applicationOrderExample}
        </p>

        <h2>Zulässige Parameter</h2>
        <p>
          Zeitbasierte Parameter müssen jeweils als Paar (start_ und end_ ) verwendet werden.<br />
          <span className='text text-info'>Achtung</span> Die Parameter müssen url-Encodiert werden!
        </p>
        <p>
          <strong>Beispiel in Javascript</strong><br />
          <code>
            urlEncodedString = encodeURIComponent("2023-04-19T09:00:00+02:00") <br />
            console.log(urlEncodedString)<br />
            # => "2023-04-19T09%3A00%3A00%2B02%3A00"
          </code>
        </p>
        <p>
          <strong>Beispiel in Ruby</strong><br />
          <code>
            url_encoded_string = CGI.escape("2023-04-19T09:00:00+02:00") <br />
            # => "2023-04-19T09%3A00%3A00%2B02%3A00"
          </code>
        </p>

        <p>
          <strong>Beispiel in PHP</strong><br />
          <code>
            url_encoded_string = urlencode("2023-04-19T09:00:00+02:00") <br />
            # => "2023-04-19T09%3A00%3A00%2B02%3A00"
          </code>
        </p>
        <p>
          <strong>Beispiel in Go</strong><br />
          <code>
            urlEncodedString := url.QueryEscape("2023-04-19T09:00:00+02:00") <br />
            fmt.Println(urlEncodedString)<br />
            # => "2023-04-19T09%3A00%3A00%2B02%3A00"
          </code>
        </p>

        <h3>Grundsätzlich</h3>
        <table className='table table-striped table-hover'>
          <thead>
            <th>Parameter</th>
            <th>Typ</th>
            <th>Beispiel</th>
          </thead>
          <tbody>
            <tr>
              <td>
                <code>order_type</code>
              </td>
              <td>
                String
              </td>
              <td>
                catalog_order ||  event_order || application_order <span className='text-muted'>(Schränkt Ergebnis auf gegebenen OrderType ein)</span>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Zeitbasiert</h3>
        <table className='table table-striped table-hover'>
          <thead>
            <th>Parameter</th>
            <th>Typ</th>
            <th>Beispiel</th>
          </thead>
          <tbody>
            <tr>
              <td>
                <code>start_time</code>
              </td>
              <td>
                <a href="https://de.wikipedia.org/wiki/ISO_8601">ISO8601-Norm Datum mit Zeit und Zeitzone / url-encoded</a>
              </td>
              <td>
                2023-04-28T18:30:00+02:00  <span className='text-muted'>(maximal 2 Monate in der Vergangenheit und benötigt gleichzeitig den Parameter 'end_time')</span>
              </td>
            </tr>
            <tr>
              <td>
                <code>end_time</code>
              </td>
              <td>
                <a href="https://de.wikipedia.org/wiki/ISO_8601">ISO8601-Norm Datum mit Zeit und Zeitzone / url-encoded</a>
              </td>
              <td>
                2023-04-30T18:30:00+02:00 <span className='text-muted'>(maximal 2 Monate in der Vergangenheit und muss nach benötigtem Parameter 'start_time' liegen)</span>
              </td>
            </tr>
          </tbody>
        </table>
        <h3>Beispiel - Zapier integration</h3>
        Um unsere Api via Zapier abzurufen und ggfls weiterzubearbeiten braucht es als Trigger
        <p className='text text-info'>Webhooks by Zapier (Premium)</p>
        <p>
          <strong>Event:</strong> <pre>Retrieve Poll</pre> <br />
          <strong>Trigger:</strong><br />
          URL: <pre>https://api.orders.austauschkompass.de/organisation/api/v1/orders</pre><br />
          Headers: <pre>Authorization | Bearer YOUR-ACCESS-TOKEN</pre>
        </p>
        <img className='img-fluid' src='zapier-1.png' alt='zapier einrichtung 1' />
        <img className='img-fluid' src='zapier-2.png' alt='zapier einrichtung 2' />
        <img className='img-fluid' src='zapier-3.png' alt='zapier einrichtung 3' />
      </div>
    </div>
    </>
  )
}

export default Docs
